import React, { Component } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import ReactTimeAgo from 'react-time-ago'
import moment from 'moment'
import { withRouter } from 'react-router-dom'    
import {APIS} from '../../config'



class Blocks extends Component {

    state = {
        myArr : [],
        transArr : []
      
    
    
      }
    
      constructor(props) {
        super(props)
        this.loadData = this.loadData.bind(this)
    }
    
      componentDidMount() {
        this.loadData()
        setInterval(this.loadData, 10000);
      }
    
      async loadData() {
         try {
          const token = sessionStorage.getItem('login')
          let res;
          if(token === null) {
           res = await fetch(APIS+'block');
          } else {
           res = await fetch(APIS+'block', {
           headers: new Headers({
             'Authorization': token }) 
           });
          }

            // const res = await fetch(APIS+'block');
    
            let blocks = await res.json();
    
    
            this.setState({myArr:blocks.data})
    
        } catch (e) {
            console.log(e);
        }
      }
    render() {
      const { history } = this.props

        return (
            <div>
                 <CCard>
            <CCardHeader>
              Blocks {' & '} Transactions
            </CCardHeader>
            <CCardBody>
              

            <table className="table table-hover table-outline mb-0 table-responsive d-sm-table">
                <thead className="thead-light">
                  <tr>
                    <th className="text-center"><CIcon name="cil-people" /></th>
                    <th>Block No</th>
                    <th className="text-center">Transactions</th>
                    {/* <th className="text-center">Block Hash</th> */}
                    {/* <th className="text-center">Details</th> */}
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.myArr.map((e, index)=>

                                <tr onClick={()=>history.push('block/'+e.id)} key={index}>
                                <td className="text-center">
                                  <div className="c-avatar">
                                    <strong>{index + 1}</strong>
                                    {/* <img src={muis} className="c-avatar-img" alt="admin@bootstrapmaster.com" />
                                    <span className="c-avatar-status bg-success"></span> */}
                                  </div>
                                </td>
                                <td>
                                  <strong>{e.id}</strong>
                                  <div className="small text-muted">
                                    <span>New</span> | Registered: <strong>{moment(e.attributes.datetime).format('LTS')}</strong>
                                  </div>
                                </td>
                                <td className="text-center">
                                  {/* <CIcon height={25} name="cif-us" title="us" id="us" /> */}
                                  <div><strong>{e.attributes.count_extrinsics_signed}</strong></div>
                                </td>
                                {/* <td className="text-center"> */}
                                  {/* <CIcon height={25} name="cif-us" title="us" id="us" /> */}
                                  {/* <div>{e.attributes.hash}</div> */}
                                {/* </td> */}
                                {/* <td> */}
                                {/* {<ReactTimeAgo date={e.attributes.datetime} locale="en-US" timeStyle="round"/>} */}
                                  {/* <div className="clearfix">
                                    <div className="float-left">
                                      <strong>50%</strong>
                                    </div>
                                    <div className="float-right">
                                      <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                                    </div>
                                  </div>
                                  <CProgress className="progress-xs" color="success" value="50" /> */}
                                {/* </td> */}
                                {/* <td className="text-center">
                                  <CIcon height={25} name="cib-cc-mastercard" />
                                </td> */}
                                <td>
                                  <div className="small text-muted">Last block created</div>
                                  <strong>{<ReactTimeAgo date = {Date.parse(e.attributes.datetime)} locale="en-US" timeStyle="round"/>}</strong>
                                </td>
                                </tr>


                 )}
                 
                </tbody>
              </table>

              </CCardBody>
          </CCard>

                
            </div>
        );
    }
}

export default  withRouter(Blocks);
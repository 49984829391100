import React, { Component } from 'react';
import {
    
    CCard,
    CCardBody,
    CCardHeader,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem
  } from '@coreui/react'

  import Users from '../users/Users'

// import { get } from 'core-js/fn/reflect';
  import { connection, balance, did, token, utils } from 'mui-metablockchain-sdk'
import {netstat} from '../../config'
import {hexToString} from '@polkadot/util'
import { encodeAddress } from '@polkadot/util-crypto';

  
let clickHoldTimer = null;

class Account extends Component {
    state = {
        balance : '',
        identifier : '',
        block : '',
        pubkeys : '',
        metadata : '',
        addresses : '',
        transArr : [],
        ssid: '',
        NotFound : false,
        tokenList: [],
        loader: false,
        selectedToken: {
            id: 0,
            name: 'MetaMUI',
        },
        filterToggle: false
    }

    async componentDidMount(){
      this.getSelectedTokenDetails();
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed

        if (prevProps.location.pathname !== this.props.location.pathname) {
            try {
                this.setState({NotFound: false})

                let str = decodeURIComponent(this.props.location.state.did);
                let ssid =  str;
                this.setState({ssid: ssid});
                let network = netstat

                let provider = await connection.buildConnection(network);
                
                let [incomingData, incomingData1, lookupData] = await Promise.all([
                    did.getDIDDetails(this.props.location.state.orginal, provider),
                    balance.getBalance(this.props.location.state.orginal, provider),
                    did.didLookupByApp({identifier: this.props.location.state.orginal}, provider),
                ]);
    
                   
                this.setState({
                    addresses: incomingData.public_keys.map(pkey => encodeAddress(pkey, 42)),
                    balance: incomingData1,
                    block: lookupData.addedOn,
                    identifier: incomingData.identifier,
                    metadata: incomingData.metadata,
                    pubkeys: incomingData.public_keys
                }) 
               } catch (e) {
                   console.log('failed',e);
                   this.setState({NotFound: true})

               }

        } else if (prevState.selectedToken !== this.state.selectedToken) {
            this.getSelectedTokenDetails();
        }

    }

    getSelectedTokenDetails = async () => {
        this.setState({loader: true})

        try {
            let incomingData1, incomingData, lookupData;
            let network = netstat
            let provider = await connection.buildConnection(network);
            var token_list = await token.getTokenList(provider).catch((err) => console.log('Get Token list SDK function throwing error..', err))
            token_list.push({name: 'MetaMUI'})
            // const token_names = token_list.map(token => token.name)
            let newList = token_list.filter((e) => e.hasOwnProperty('name') && e.name !== "Yidindji Dollar");
            this.setState({tokenList: newList});
           let str ;
           if( this.props.location.state === undefined){


                str = this.props.location.pathname.substr(9)

               [incomingData, incomingData1, lookupData] = await Promise.all([
                   did.getDIDDetails(str, provider),
                   balance.getBalance(str, provider),
                   did.didLookupByApp({identifier: this.props.location.state.orginal}, provider),
               ]);


           } else if (this.state.selectedToken && this.state.selectedToken.name !== 'MetaMUI') {
                str = decodeURIComponent(this.props.location.state.did);

               [incomingData, incomingData1, lookupData] = await Promise.all([
                   did.getDIDDetails(this.props.location.state.orginal, provider),
                   token.getTokenBalance(str, this.state.selectedToken.currencyCode, provider),
                   did.didLookupByApp({identifier: this.props.location.state.orginal}, provider),
               ]);


                console.log('Selected Token balance is.........', decodeURIComponent(this.props.location.state.did), incomingData1);
           }else{
                str = decodeURIComponent(this.props.location.state.did);

               [incomingData, incomingData1, lookupData] = await Promise.all([
                   did.getDIDDetails(this.props.location.state.orginal, provider),
                   balance.getBalance(this.props.location.state.orginal, provider),
                   did.didLookupByApp({identifier: this.props.location.state.orginal}, provider),
               ]);

           }
           let ssid =  str;
           this.setState({ssid: ssid});    
            this.setState({loader: false});
            this.setState({
                addresses: incomingData.public_keys.map(pkey => encodeAddress(pkey, 42)),
                balance: incomingData1,
                block: lookupData.addedOn,
                identifier: incomingData.identifier,
                metadata: incomingData.metadata,
                pubkeys: incomingData.public_keys,
            })
   
       } catch (e) {
           console.log('failed',e);
           this.setState({NotFound: true})
           this.setState({loader: false});
       }
    }

    handleFilterShow = () => {
        clickHoldTimer = setTimeout(() => {
            this.setState({filterToggle: !this.state.filterToggle});
        }, 1000);
    }

    handleFilterHide = () => {
        clearTimeout(clickHoldTimer);
    }

    render() {
        return (
            <div>
                 <CCard>
            <CCardHeader style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <div onMouseDown={this.handleFilterShow} onMouseUp={this.handleFilterHide}>Blocks {' & '} Transactions</div>
                {
                    this.state.tokenList.length >= 1 && this.state.filterToggle && (
                        <div>
                            <CDropdown className="mt-2">
                                <CDropdownToggle caret color="info">
                                    {this.state.selectedToken.name}
                                </CDropdownToggle>
                                <CDropdownMenu style = {{height : "250px", overflowY : "scroll"}}>
                                    {
                                        this.state.tokenList.filter(tokenObj => tokenObj.name !== this.state.selectedToken.name).map((token, i) => {
                                            return <CDropdownItem onClick={() => this.setState({selectedToken: token})}  key={`${i}_${token.id}`}>{token.name}</CDropdownItem>
                                        })
                                    }
                                </CDropdownMenu>
                            </CDropdown>
                        </div>
                    )
                }
            </CCardHeader>
            <CCardBody>{this.state.NotFound?<div>Data Not Found</div>:
              

            <table className="table table-striped table-hover table-outline mb-0 table-responsive d-sm-table">
                
                <tbody>
                <tr >
                    <td>Balance : </td>
                    <td><strong style={{display: 'flex', flexDirection: 'row'}}>{this.state.loader ? <p style={{color: 'yellowgreen'}}>Loading...</p> : this.state.balance} {this.state.selectedToken.name} </strong></td>
                </tr>
                <tr >
                    <td>Added Block :</td>
                    <td><strong>{this.state.block}</strong></td>
                </tr>
                <tr >
                    <td>Identifier :</td>
                    <td><strong>{utils.hexToString(this.state.identifier)}</strong></td>
                </tr>
                <tr >
                    <td>Public Keys :</td>
                    <td>{this.state.pubkeys && this.state.pubkeys.map(pkey => {
                            return <p><strong>{pkey}</strong></p>
                        })}
                    </td>
                </tr>
                <tr >
                    <td>Metadata :</td>
                    <td><strong>{this.state.metadata}</strong></td>
                </tr>
                <tr >
                 <td>Account Ids :</td>

                <td>{this.state.addresses && this.state.addresses.map(address => {
                    return <p><strong>{address}</strong></p>
                })}
                </td>
                </tr>
                 
                </tbody>
              </table>}

              </CCardBody>
          </CCard>

          <CCard/>



          <CCard>
            <CCardHeader>
              Blocks {' & '} Transactions
            </CCardHeader>
            <CCardBody>
              
          {this.state.ssid &&
           <Users ssid={this.state.ssid} selectedToken={this.state.selectedToken}/> }

              </CCardBody>
          </CCard>

          <CCard/>
            </div>
        );
    }
}

export default Account;
import React, { Component } from "react";
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CContainer,
} from "@coreui/react";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { connection as CSK, balance, did } from "mui-metablockchain-sdk";
import { netstat } from "../../config";

let name;

const didName = (state) => {
  name = state.count || sessionStorage.getItem("userDid");
  return {
    count: name,
  };
};

class Profile extends Component {
  state = {
    identifier: "",
    public_keys: [],
    added_block: "",
    meta_data: "",
    balance: "",
  };

  async componentDidMount() {
    try {
      let provider = await CSK.buildConnection(netstat);

      let [convertData, lookupData] = await Promise.all([
        did.getDIDDetails(name, provider),
        did.didLookupByApp({ identifier: name }, provider),
      ]);
      let balanceData = await balance.getBalance(name, provider);

      this.setState({
        identifier: convertData.identifier,
        public_keys: convertData.public_keys,
        added_block: lookupData.addedOn,
        meta_data: convertData.metadata,
        balance: balanceData,
      });
      console.log(convertData);
    } catch (err) {
      console.log(err);
    }
  }

  componentDidCatch;

  render() {
    const classes = Styles();
    return (
      <div className="docs-example-row">
        <CContainer>
          <CRow>
            <CCol>
              <CCard>
                <CCardHeader>User Profile</CCardHeader>
                <CCardBody>
                  <div className="one">
                    <CRow>
                      <CCol>
                        <TextField
                          // className={classes.textfield}
                          id="one"
                          label="DID Name"
                          value={name === "false" ? "" : name}
                          size="small"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </CCol>
                      <CCol>
                        <TextField
                          // className={classes.textfield}
                          size="small"
                          id="two"
                          label="Created Block"
                          value={this.state.added_block}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </CCol>
                      <CCol>
                        <TextField
                          // className={classes.textfield}
                          size="small"
                          id="outlined-read-only-input"
                          label="META MUI BALANCE"
                          value={this.state.balance}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </CCol>
                    </CRow>
                  </div>
                  <div style={classes.cbody}>
                    <CRow>
                      <CCol>
                        <TextField
                          // className={classes.textfield}
                          size="small"
                          id="outlined-read-only-input"
                          label="MetaData"
                          value={this.state.meta_data}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </CCol>
                    </CRow>
                  </div>

                  {this.state.public_keys?.map((public_key, i) => {
                    return (
                      <div style={classes.cbody}>
                        <CRow>
                          <CCol>
                            <TextField
                              // className={classes.textfield}
                              size="small"
                              id="outlined-read-only-input"
                              label={"Public Key " + (i + 1)}
                              value={public_key}
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </CCol>
                        </CRow>
                      </div>
                    );
                  })}
                  {this.state.public_keys?.length == 0 && (
                    <div style={classes.cbody}>
                      <CRow>
                        <CCol>
                          <TextField
                            // className={classes.textfield}
                            size="small"
                            id="outlined-read-only-input"
                            label="Public Keys"
                            value={this.state.public_keys}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        </CCol>
                      </CRow>
                    </div>
                  )}

                  <div style={classes.cbody}>
                    <CRow>
                      <CCol>
                        <TextField
                          // className={classes.textfield}
                          size="small"
                          id="outlined-read-only-input"
                          label="Identifier"
                          value={this.state.identifier}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </CCol>
                    </CRow>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default connect(didName)(Profile);

// const Profile = () => {

//     const didName = useSelector(state => state.count)
//     const classes = useStyles();
//     const [identifier, setIdenty] = useState('');
//     const [public_keys, setPub] = useState('mmmmm');
//     const [metadata, setMeta] = useState('');
//     const [added_block, setBlock] = useState('');
//     const countRef = useRef('nothing');

//     console.log("diName", didName)

//     useEffect(()=>{
//         if(didName !== 'false'){
//             getDetails()

//         }

//     },[])

//     const getDetails = async ()=>{
//         let provider = await CSK.buildConnection(netstat);
//         let convertData = await did.getDIDDetails(didName,provider)
//         console.log("got",convertData)
//         await setIdenty(convertData.identifier)
//         await setPub(convertData.public_keys)
//         await setMeta(convertData.metadata)
//         await  setBlock(convertData.added_block)
//          countRef.current = convertData.public_keys
//         console.log("gotasaaa",public_keys)

// }

//     return (

//     );
// };

// export default Profile;

const Styles = (theme) => ({
  cbody: {
    marginTop: 35,
  },
});

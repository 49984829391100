import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";
import one from '../../assets/icons/2.png'
import two from '../../assets/icons/3.png'
import four from '../../assets/icons/image.png'

const CarouselPage = () => {
  return (
    <MDBContainer >
      <MDBCarousel
      activeItem={1}
      length={2}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner >
      <a href="https://swnglobal.com/meta-blockchain/" rel="noreferrer" target="_blank" >

        <MDBCarouselItem itemId="1">
          <MDBView>
          {/* <div className="container"> */}
        
            <img style={{'height': '120px'}} 
              className="d-block w-100 "
               src={one}
               alt="First slide"
            />

        <div className="overlay">
            <div className="text">MUI MetaBlockchain is the first identity-based blockchain designed for national digital currency issuance.
            <p><strong>  CLICK HERE...</strong></p>
            </div>
            

          </div>

            {/* </div> */}
          <MDBMask overlay="black-light" />

          </MDBView>
          <MDBCarouselCaption>
            {/* <h3 className="h3-responsive">Light mask</h3>
            <p>First text</p> */}
          </MDBCarouselCaption>

        </MDBCarouselItem>
        </a>
        <a href="https://swnglobal.com/mdex/" rel="noreferrer" target="_blank">

        {/* <MDBCarouselItem itemId="2">
          <MDBView>
            <img style={{'height': '120px'}}
              className="d-block w-100"
              src={two}
              alt="Second slide"
            />

<div className="overlay">
            <div className="text">Metablock Exchange is an identity-powered digital asset exchange, and is a key part of the MUI ecosystem.
            <p><strong>  CLICK HERE...</strong></p>
            </div>
            

          </div>
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
          
          </MDBCarouselCaption>
        </MDBCarouselItem> */}
        </a>
      
       
        <a href="https://apps.apple.com/eg/app/mui-metawallet/id1546698261" rel="noreferrer" target="_blank">

        <MDBCarouselItem itemId="2">
          <MDBView>
            <img style={{'height': '120px'}}
              className="d-block w-100"
              src={four}
              alt="Third slide"
            />

<div className="overlay">
            <div className="text">MUI MetaWallet is the first asset wallet with identity-based transfers. It is the first and only wallet that is able to connect to the MUI MetaBlockchain mainnet.
            <p><strong>  CLICK HERE...</strong></p>
            </div>
            

          </div>
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          
          </MDBCarouselCaption>
        </MDBCarouselItem>
        </a>
       
      </MDBCarouselInner>
    </MDBCarousel>
    </MDBContainer>
  );
}

export default CarouselPage;
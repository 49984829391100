import axios from "axios";
import { AUTH_SERVER, PASSKEY_DOMAIN } from "src/config";


const checkIfPasskeyRegistered = async (did) => { 
    return axios.get(AUTH_SERVER + "api/passkeys/register/check", {
        params: {
            did,
        },
        headers: {
            "Content-Type": "application/json",
        },
    }).then(data => data?.data);
}

const startPassKeyRegister = async (did, token) => {
    return axios.post(AUTH_SERVER + "api/passkeys/register/start", {
        did, domain: PASSKEY_DOMAIN,
        }, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": token,
        },
    }).then(data => data.data);
}

const finishPassKeyRegister = async (credential, did, token) => { 
    return axios.post(
        AUTH_SERVER + "api/passkeys/register/finish", {
        credential,
        did,
        domain: PASSKEY_DOMAIN,
    }, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": token,
        },
    }
    ).then(data => data.data);
}


const startPassKeyAuth = async (did) => {
    return axios.post(AUTH_SERVER + "api/passkeys/authenticate/start", {
        did, domain: PASSKEY_DOMAIN,
        }, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then(data => data.data);
}

const finishPassKeyAuth = async (credential, did) => { 
    return axios.post(
        AUTH_SERVER + "api/passkeys/authenticate/finish", {
        credential,
        did,
        domain: PASSKEY_DOMAIN,
    }, {
        headers: {
            "Content-Type": "application/json",
        },
    }
    ).then(data => data.data);
}


export {
    checkIfPasskeyRegistered,
    startPassKeyRegister,
    finishPassKeyRegister,
    startPassKeyAuth,
    finishPassKeyAuth,
};
import React, {useState, useEffect} from 'react';
import {APIS} from '../../config';
import { utils } from 'mui-metablockchain-sdk';

function EventLanding(props) {

  const [logList, setLogList] = useState();
  const [activePage, setActivePage] = useState(1);
  const [ref, setRef] = useState(false)
  
  const getList = async () => {
    const token = await sessionStorage.getItem('login')
    let res1;
    res1 = await fetch(APIS+'event/'+`?page[size]=10&page[number]=${activePage}`);
    res1 = await res1.json();
    if(res1.hasOwnProperty('data')) {
      if(res1.data.length > 0) {
        setLogList(res1.data);
      } else {
        setLogList("Nothing")
      }
    } else {
      setLogList("Error")
    }
  }

  useEffect(() => {
    getList()
  }, [ref])

  function getTableState() {
    if(logList) {
       if(logList === "Error") {
         return (
          <div className = "row justify-content-center">
            <div className = "col-6 text-center">
              <table className = "table table-striped ">
                <thead><tr><th scope = "col">Message</th></tr></thead>
                <tbody>
                  <tr>
                    <td>
                      Something Went Wrong
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
         )
       } else if (Array.isArray(logList)) {
         return (
          <div className = "row justify-content-center">
            <div className = "col-11">
              <table className = "table table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope = "col">Block No.</th>
                    <th scope = "col">Type</th>
                    <th scope = "col">Module</th>
                    <th scope = "col">Attributes</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    logList && logList.map((e, i) => (
                      <tr key = {i}>
                        <td>{e.attributes.block_id}</td>
                        <td>{e.attributes.event_id}</td>
                        <td>{e.attributes.module_id}</td>
                        <td>
                          {
                            e.attributes.attributes && e.attributes.attributes.map((se,j) => (
                              <table className = "table table-info" key = {j}>
                                <tbody>
                                  <tr>
                                    <td>{se.type === "bool" ? "All actions completed" : se.type}</td>
                                    <td>
                                      {
                                        se.value && (
                                          typeof se.value === "object" ? 
                                          (
                                            se.type === "AuthorityList" ? se.value[0].AuthorityId.value : 
                                            se.type === "DispatchResult" ? "OK" : "Object"
                                          ) 
                                          :
                                          (
                                            se.type === "Did" ? utils.hexToString(se.value) : 
                                            se.type === "CurrencyCode" ? utils.hexToString(se.value) : 
                                            se.type === "bool" ? ( se.type ? "Yes" : "No") :
                                            se.value
                                          )
                                        )
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            ))
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
         );
       }
    } else {
      return (
        <div className = "row justify-content-center">
          <div className = "col-6 text-center">
            <table className = "table table-striped ">
              <thead><tr><th scope = "col">Message</th></tr></thead>
              <tbody>
                <tr>
                  <td>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div className = "container">
      <div className = "card">
        <div className = "card-body">
          {getTableState()}
        </div>
        <div className = "card-footer">
          <div className = "row justify-content-center">
            <div className = "col-2">
              <div className="btn-group" role="group" aria-label="Pagination">
                <button type="button" className="btn btn-outline-dark" onClick = {() => {setActivePage(activePage - 1); setRef(!ref); setLogList(undefined);}} disabled = {activePage === 1 ? true : false}>Prev</button>
                <button type="button" className="btn btn-primary" disabled = {true}>{activePage}</button>
                <button type="button" className="btn btn-outline-dark me-3" onClick = {() => {setActivePage(activePage + 1); setRef(!ref); setLogList(undefined);}}>Next</button>
                <button className = "btn btn-outline-info" onClick = {() => {setRef(!ref); setLogList(undefined);}}>Refresh</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventLanding;
import React, { useState, useEffect } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import moment from 'moment'
import {APIS} from '../../config'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';


const User = ({match}) => {//#endregion

  const [tx, setTx] = useState()
  const [bn, setBn] = useState()

  const [time, setTime] = useState()
  const [S, setS] = useState()
  const [R, setR] = useState()
  const [memo, setMemo] = useState()
  const [amt, setAmt] = useState()
  const [hash, setHash] = useState()
  const [sig, setSign] = useState()
  const [call, setCall] = useState()
  const [stat, setStat] = useState()



  useEffect(() => {
   
    getData(match)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


const getData = async(match)=>{
      const token = await sessionStorage.getItem('login')
      let res1;
      if(token !== null){
        res1 = await fetch(APIS+'extrinsic/'+match.params.id, {
          headers: new Headers({
            'Authorization': sessionStorage.getItem('login') }) 
          });
        res1 = await res1.json();
      }else{
        res1 = await fetch(APIS+'extrinsic/'+match.params.id);
        res1 = await res1.json();
        
      }
      console.log('Selected Token name is.......', match.params.selectedToken)
      if(res1 !== null && (match.params.selectedToken === 'MetaMUI')){
        let data = res1.data.attributes;
        let Sender = data?.event_params?.sender || hex2a(data.params?.[0]?.value?.call_args?.[0]?.value) || '';
        // console.log('address', data.address)

        // Sender = await hexToString(Sender)
        let Memo = await data?.event_params?.memo || data.params?.[0]?.value?.call_args?.[3]?.value || '';

        // Sender = await formatName(Sender)
        let Receiver = data?.event_params?.receiver || hex2a(data.params?.[0]?.value?.call_args?.[1]?.value) || '';
        // Receiver = await hexToString(Receiver)
        // Receiver = await formatName(Receiver)
        let amount = (data.params[1]?.value || data.params?.[0]?.value?.call_args?.[2]?.value) / 1e6;

        let call_id = data.call_id;
        if (call_id === "sudo") {
          call_id = "transfer_with_memo";
        }

        setTx(data)
        setAmt(amount)
        setBn(data.block_id);
        setCall(call_id);
        setHash(data.extrinsic_hash)
        setSign(data.signature);
        setR(Receiver)
        setS(Sender)
        setMemo(Memo)
        setTime(data.datetime)
        if(data.error === 1){
          setStat(data.error_message)
        } 
        if(data.error === 0){
          setStat('success')
        }


      }else if (res1 !== null && match.params.selectedToken !== 'MetaMUI') {
        let data = res1.data.attributes;
        let Sender =  data?.event_params?.sender || '';
        // console.log('address', data.address)

        // Sender = await hexToString(Sender)
        let Memo = await data?.event_params?.memo || '';

        // Sender = await formatName(Sender)
        let Receiver =  data?.event_params?.receiver || '';

        let amount =  data?.event_params?.amount || '';

        setTx(res1.data.attributes)
        setAmt(amount);
        setBn(data.block_id);
        setCall(data.call_id);
        setHash(data.extrinsic_hash)
        setSign(data.signature);
        setR(Receiver)
        setS(Sender)
        setMemo(Memo)
        setTime(data.datetime)
        if(data.error === 1){
          setStat(data.error_message)
        } 
        if(data.error === 0){
          setStat('success')
        }
      }else{
        console.log('API IS NOT RESPONDING')
      }
}

  function hex2a(hexx) {
    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    str = str.replace(/\x00/g, '');
    return maskDID(str);
  }

  function maskDID(input) {
    // Extract the ssid part after "did:ssid:"
    const didSplits = input.split(':');

    // If didSplits exists, create a masked version with the first two characters followed by asterisks
    if (didSplits[2]) {
      const maskedSSID = `${didSplits[0]}:${didSplits[1]}:${didSplits[2].substring(0, 2)}${'*'.repeat(32 - 4 - didSplits[0].length - didSplits[1].length)}`;
      return maskedSSID;
    } else {
      // Return the input unchanged if the format is not as expected
      return input;
    }
  }

  return (
    <CRow>
      <CCol lg={12}>
        <CCard>
          <CCardHeader>
            Block id: {match.params.id}
          </CCardHeader>
          <CCardBody>
            {tx?
              <table className="table table-striped table-hover table-responsive">
                <tbody>
                  
                        <tr>
                          <td>Block No :</td>
                          <td><strong>{bn}</strong></td>
                        </tr>
                        <tr>
                          <td>Timestamp :</td>
                          <td><strong>{moment(time).format('MMMM Do YYYY, h:mm:ss a')}</strong></td>
                        </tr>
                        <tr>
                          <td>Transaction Hash :</td>
                          <td><strong>{hash}</strong></td>
                        </tr>
                        <tr>
                          <td>Sender :</td>
                          <td><strong>{S}</strong></td>
                        </tr>
                        <tr>
                          <td>Receiver :</td>
                          <td><strong>{R}</strong></td>
                        </tr>
                        <tr>
                          <td>Memo :</td>
                          <td><strong>{memo}</strong></td>
                        </tr>
                        <tr>
                          <td>Amount :</td>
                          <td><strong>{amt} {`${match.params.selectedToken !== 'MetaMUI' ? match.params.selectedToken : 'MetaMUI'}`}</strong></td>
                        </tr>
                        <tr>
                          <td>Signature :</td>
                          <td><strong>{sig}</strong></td>
                        </tr>
                        <tr>
                          <td>Call :</td>
                          <td><strong>{call}</strong></td>
                        </tr>
                        <tr>
                          <td>Transaction Status :</td>
                          <td><strong>{stat === 'success'?<p><CheckCircleIcon style={{'color': 'green'}}/> Success</p>: <p><ThumbDownIcon style={{'color': 'red'}}/>  Failed ({stat})</p>}</strong></td>
                        </tr>
                   
                </tbody>
              </table>:<div>Data Not Available Right Now..</div>}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default User

import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";

import CIcon from "@coreui/icons-react";
import ReactTimeAgo from "react-time-ago";
import moment from "moment";

import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import WidgetsDropdown from "../widgets/WidgetsDropdown.js";

import CarouselPage from "./Carousel";
import { APIS } from "../../config";

class Dashboard extends Component {
  state = {
    myArr: [],
    transArr: [],
    intervalId: "",
  };

  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    let intervalId = setInterval(this.loadData, 10000);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this.setState = (state, callback) => {
      return;
    };
  }

  async loadData() {
    try {
      const token = sessionStorage.getItem('login');

      let res, res1;
      if (token === null) {
        res = await fetch(APIS + "block");
        res1 = await fetch(APIS + "balances/transfer");
      } else {
        res = await fetch(APIS + "block", {
          headers: new Headers({
            Authorization: token,
          }),
        });
        res1 = await fetch(APIS + "balances/transfer", {
          headers: new Headers({
            Authorization: token,
          }),
        });
      }

      // const res = await fetch(APIS+'block');
      // const res1 = await fetch(APIS+'balances/transfer');

      let blocks = await res.json();
      let balanceTrans = await res1.json();

      var citrus = blocks.data.slice(0, 6);
      var citrus1 = balanceTrans.data.slice(0, 5);

      this.setState({ myArr: citrus, transArr: citrus1 });
      // console.log(this.state.myArr)
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { history } = this.props;

    return (
      <div>
        <>
          <div>
            <WidgetsDropdown />

            <div className="img-responsive">
              <CCard />

              <CCol>{/* <CarouselPage /> */}</CCol>
              <br></br>
              <CCard />
            </div>

            {/* <WidgetsBrand withCharts/> */}

            <CRow>
              <CCol>
                <CCard>
                  <CCardHeader>
                    <strong> Blocks {" & "} Transactions </strong>
                  </CCardHeader>
                  <CCardBody>
                    <table className="table table-hover table-outline mb-0 table-responsive d-sm-table">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center">
                            <CIcon name="cil-people" />
                          </th>
                          <th>Block No</th>
                          <th className="text-center">Transactions</th>
                          {/* <th>Age</th> */}
                          {/* <th className="text-center">Details</th> */}
                          <th>Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.myArr.map((e, index) => (
                          <tr
                            onClick={() => history.push("/block/" + e.id)}
                            key={index}
                          >
                            <td className="text-center">
                              <div className="c-avatar">
                                <strong>{index + 1}</strong>
                              </div>
                            </td>
                            <td>
                              <strong>{e.id}</strong>
                              <div className="small text-muted">
                                <span>New</span> | Registered{" "}
                                <strong>
                                  :{" "}
                                  {moment(e.attributes.datetime).format("LTS")}
                                </strong>
                              </div>
                            </td>
                            <td className="text-center">
                              {/* <CIcon height={25} name="cif-us" title="us" id="us" /> */}
                              <div>
                                <strong>
                                  {e.attributes.count_extrinsics_signed}
                                </strong>
                              </div>
                            </td>
                            {/* <td> */}
                            {/* {<ReactTimeAgo date={e.attributes.datetime} locale="en-US" timeStyle="round"/>} */}
                            {/* <div className="clearfix">
                                    <div className="float-left">
                                      <strong>50%</strong>
                                    </div>
                                    <div className="float-right">
                                      <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                                    </div>
                                  </div>
                                  <CProgress className="progress-xs" color="success" value="50" /> */}
                            {/* </td> */}
                            {/* <td className="text-center">
                                  <CIcon height={25} name="cib-cc-mastercard" />
                                </td> */}
                            <td>
                              <div className="small text-muted">
                                Last block created
                              </div>
                              <strong>
                                {
                                  <ReactTimeAgo
                                    date={Date.parse(e.attributes.datetime)}
                                    locale="en-US"
                                    timeStyle="round"
                                  />
                                }
                              </strong>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CCardBody>
                </CCard>

                <CCard />
                <CCard>
                  <CCardHeader>
                    <strong> MetaMUI Transactions </strong>
                  </CCardHeader>

                  <CCardBody>
                    <table className="table table-hover table-outline mb-0 table-responsive d-sm-table">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center">
                            <CIcon name="cil-people" />
                          </th>
                          <th>Sender</th>
                          <th className="text-center">Receiver</th>
                          {/* <th className="text-center">Amount</th> */}
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.transArr.map((e, index) => (
                          <tr
                            onClick={() =>
                              history.push(`/tnx-details/${e.id}/${"MetaMUI"}`)
                            }
                            key={index}
                          >
                            <td className="text-center">
                              <div className="c-avatar">
                                <strong>{index + 1}</strong>
                              </div>
                            </td>
                            <td>
                              <div>
                                <strong>
                                  {e.attributes.sender.attributes.address}
                                </strong>
                              </div>
                              <div className="small text-muted">
                                <span>Block</span> | Added{" "}
                                <strong> : {e.id}</strong>
                              </div>
                            </td>
                            <td className="text-center">
                              {/* <CIcon height={25} name="cif-us" title="us" id="us" /> */}
                              <div>
                                <strong>
                                  {e.attributes.destination.attributes.address}
                                </strong>
                              </div>
                            </td>

                            {/* <td className="text-center">
                            <CIcon height={25} name="cib-cc-mastercard" />
                          </td> */}
                            <td>
                              <div className="small text-muted">
                                Last block created
                              </div>
                              <strong>
                                {e.attributes.value / 1e6} MetaMUI{" "}
                              </strong>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <br />

            {/* <WidgetsBrand withCharts/> */}
          </div>
        </>
      </div>
    );
  }
}

export default withRouter(Dashboard);

const isDev = process.env.REACT_APP_ISDEV;
const APIS =
  isDev === "true"
    ? process.env.REACT_APP_TESTNET_API
    : process.env.REACT_APP_MAINNET_API;
const netstat = isDev === "true" ? "dev" : "mainnet";
// const PAIRWISE_API = (isDev === 'true' ? 'http://54.179.183.182:3001' : 'http://54.179.183.182:3001')
// const PAIRWISE_WEBRTC = (isDev === 'true' ? 'ws://54.179.183.182:3001' : 'ws://54.179.183.182:3001')
const PAIRWISE_API =
  isDev === "true"
    ? process.env.REACT_APP_PAIRWISE_API_DEV
    : process.env.REACT_APP_PAIRWISE_API_PROD;
const PAIRWISE_WEBRTC =
  isDev === "true"
    ? process.env.REACT_APP_PAIRWISE_WEBRTC_DEV
    : process.env.REACT_APP_PAIRWISE_WEBRTC_PROD;
const PAIRWISE_WEBRTC_V2 =
  isDev === "true"
    ? process.env.REACT_APP_PAIRWISE_WEBRTC_DEV_2
    : process.env.REACT_APP_PAIRWISE_WEBRTC_PROD_2;
const REACT_APP_EXPLORER_DID =
  isDev === "true"
    ? process.env.REACT_APP_EXPLORER_DID_DEV
    : process.env.REACT_APP_EXPLORER_DID_PROD;

const AUTH_SERVER =
  isDev === "true"
    ? process.env.REACT_APP_AUTH_DEV
    : process.env.REACT_APP_AUTH_PROD;

const PASSKEY_DOMAIN = isDev === "true" ? "metamui.money" : "metamui.id";

const METAMUI_ADMINS = isDev === "true"
  ? [
      "did:ssid:swnglobal",
      "did:ssid:test-council",
      "did:ssid:swn",
      "did:ssid:fenn",
      "did:ssid:sumeet"
    ]
  : [
      "did:ssid:phantom",
      "did:ssid:angelkim1026"
    ];

module.exports = {
  APIS,
  netstat,
  PAIRWISE_API,
  PAIRWISE_WEBRTC,
  PAIRWISE_WEBRTC_V2,
  REACT_APP_EXPLORER_DID,
  AUTH_SERVER,
  METAMUI_ADMINS,
  PASSKEY_DOMAIN,
};

import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'false',
  count : ''
}


const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'test':
        // console.log('inside test', state.count, rest)
        return {...state, ...rest }

    default:
      return state
  }
}

const store = createStore(changeState)
export default store
//import React from 'react'
import {
  CWidgetDropdown,
  CRow,
  CCol,
} from '@coreui/react'
import ChartLineSimple from '../charts/ChartLineSimple'
import ChartBarSimple from '../charts/ChartBarSimple'
import {buildConnection} from 'mui-metablockchain-sdk/src/connection'
import Elapsed from './Elapsed'
import {netstat} from '../../config'


import React, { Component } from 'react';

class WidgetsDropdown extends Component {

  state = {
    block_no : '0',
    tnx_count : '0',
    module : '0',
    runtime : '0',
  }

 async componentDidMount() {
    const api = await buildConnection(netstat)
    const ds = await (await api.query.balances.totalIssuance()).toHuman();
    const update = ds.replace("MMUI", "Million");
   // this.loadData()
    //setInterval(this.loadData, 1000);
     await api.rpc.chain.subscribeNewHeads(async(lastHeader) => {
      let final = await api.derive.chain.bestNumberFinalized()
       let timer = await api.query.timestamp.now()    
        this.setState({block_no : `${lastHeader.number}`,tnx_count:final.words[0], module: timer, runtime:update})
      
   });

  }
  
  render(){
  return (
    <CRow>
      <CCol sm="6" lg="3">
        <CWidgetDropdown
          color="gradient-primary"
          header={this.state.block_no.toString()}
          text="Latest block"
          footerSlot={
            <ChartLineSimple
              pointed
              className="c-chart-wrapper mt-3 mx-3"
              style={{height: '70px'}}
              dataPoints={[65, 59, 84, 84, 51, 55, 40]}
              pointHoverBackgroundColor="primary"
              label="BlockTime"
              // labels="months"
            />
          }
        >
       
        </CWidgetDropdown>
      </CCol>
  
      <CCol sm="6" lg="3">
        {/* <CWidgetDropdown
          color="gradient-info"
          header = {<Elapsed value={this.state.module} />}
          text="Block Time"
          footerSlot={
            <ChartLineSimple
              pointed
              className="mt-3 mx-3"
              style={{height: '70px'}}
              dataPoints={[1, 18, 9, 17, 34, 22, 11]}
              pointHoverBackgroundColor="info"
              options={{ elements: { line: { tension: 0.00001 }}}}
              label="Transaction"
              labels="months"
            />
          }
        >
          
        </CWidgetDropdown> */}
        <Elapsed value = {this.state.module} style = {{height: "158px"}}/>
      </CCol>

      <CCol sm="6" lg="3">
        <CWidgetDropdown
          color="gradient-warning"
          // header={this.state.module}
          header={this.state.tnx_count.toString()}
          text="Confirmed Block"
          footerSlot={
            <ChartLineSimple
              className="mt-3"
              style={{height: '70px'}}
              backgroundColor="rgba(255,255,255,.2)"
              dataPoints={[78, 81, 80, 45, 34, 12, 40]}
              options={{ elements: { line: { borderWidth: 2.5 }}}}
              pointHoverBackgroundColor="warning"
              label="Module"
              labels="months"
            />
          }
        >
      
        </CWidgetDropdown>
      </CCol>

      <CCol sm="6" lg="3">
        <CWidgetDropdown
          color="gradient-danger"
          header={this.state.runtime}
          text="MetaMUI Total Issuance"
          footerSlot={
            <ChartBarSimple
              className="mt-3 mx-3"
              style={{height: '70px'}}
              backgroundColor="rgb(250, 152, 152)"
              label="Runtime"
              labels="months"
            />
          }
        >
        
        </CWidgetDropdown>
      </CCol>
    </CRow>
  )
}
}

export default WidgetsDropdown

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'
 import imageName from "../assets/icons/m.png";
import imageName1 from "../assets/icons/m2.png";
import CIcon from '@coreui/icons-react'
import muitok from '../assets/icons/MUI-TOKEN.png'

// sidebar nav config
import navigation from './_nav'

const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)
  const names = useSelector(state => state.count)

  return (
    <CSidebar
      show = {show}
      onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon
          className="c-sidebar-brand-full"
          // name="logo-negative"
          src={imageName}
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-full"
          // name="logo-negative"
          src={imageName1}
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          src={muitok}
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
      <p  className="c-sidebar-brand-full"><strong style={{margin: '50px'}}>{names || sessionStorage.getItem("userDid")}</strong></p>
    
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)

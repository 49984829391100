import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <h6> Explorer Version 1.2 &copy; 2021 Sovereign Wallet Network.</h6>
      </div>
      <div className="mfs-auto">
        <h6>Powered by MetaMUI Blockchain Network</h6>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)

import React, { Component } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
} from '@coreui/react'

import { withRouter } from 'react-router-dom'    
 import moment from 'moment'
 import {APIS} from '../../config'

class Block extends Component {

    state = {
        timestamp : '',
        hash : '',
        parent : '',
        sroot : '',
        eroot : '',
        tnx : '',
        ssid: '',
        block: '',
        NotFound : false,
        tnxdata : []
    }

    async componentDidMount(){
      
            try {
               let str = this.props.location.pathname;
               let ssid =  str.substr(7);

               this.setState({ssid: ssid})

               const token = sessionStorage.getItem('login')
               let res;
               if(token === null) {
                res = await fetch(APIS+'/block/'+ssid+'?include=transactions,inherents,events,logs');
               } else {
                res = await fetch(APIS+'/block/'+ssid+'?include=transactions,inherents,events,logs', {
                headers: new Headers({
                  'Authorization': token }) 
                });
               }

              //  let res = await fetch(APIS+'/block/'+ssid+'?include=transactions,inherents,events,logs');
       
               res = await res.json();
               res = res.data ;
               if(res.relationships.transactions.data.length>0){
                this.setState({tnxdata: res.relationships.transactions.data});

               }
              let t =  moment(res.attributes.datetime).format('MMMM Do YYYY, h:mm:ss a'); // January 29th 2021, 3:31:23 pm

               
              this.setState({timestamp:t,hash:res.attributes.hash,parent:res.attributes.parent_hash,
            sroot:res.attributes.state_root,eroot:res.attributes.extrinsics_root,tnx:res.attributes.count_extrinsics_signed, block:res.attributes.id})
       
           } catch (e) {
               console.log('failed',e);
               this.setState({NotFound: true})
           }
        
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.location.pathname !== this.props.location.pathname) {
            try {
                //    console.log(this.props.location.pathname)
                   let str = this.props.location.pathname;
                   let ssid =  str.substr(7);
    
                   this.setState({ssid: ssid})
                   const token = sessionStorage.getItem('login')
                  let res;
                  if(token === null) {
                    res = await fetch(APIS+'block/'+ssid+'?include=transactions,inherents,events,logs');
                  } else {
                    res = await fetch(APIS+'block/'+ssid+'?include=transactions,inherents,events,logs', {
                    headers: new Headers({
                      'Authorization': token }) 
                    });
                  }
    
                  //  let res = await fetch(APIS+'block/'+ssid+'?include=transactions,inherents,events,logs');
           
                   res = await res.json();
                   res = res.data ;
                 
                   
                  this.setState({timestamp:res.attributes.datetime,hash:res.attributes.hash,parent:res.attributes.parent_hash,
                sroot:res.attributes.state_root,eroot:res.attributes.extrinsics_root,tnx:res.attributes.count_extrinsics_signed, block:res.attributes.id})
                this.setState({NotFound: false})
                if(res.relationships.transactions.data.length>0){
                    this.setState({tnxdata: res.relationships.transactions.data});

                }else{
                    this.setState({tnxdata:''});


                }

               } catch (e) {
                   console.log('failed',e);
                   this.setState({NotFound: true})
               }

        }
        
    }


    render() {

        const { history} = this.props;

        return (
            <div>
                <div>
                 {this.state.NotFound? <CCard>
            <CCardHeader>
              Blocks
            </CCardHeader>
            <CCardBody>
              
             BLOCK DATA NOT FOUND

              </CCardBody>
          </CCard>:
                 <CCard>
            <CCardHeader>
              Blocks {' @ '} {this.state.block} 
            </CCardHeader>
            <CCardBody>
              

            <table className="table table-striped table-hover table-outline mb-0 d-none d-sm-table">
                
                <tbody>
                <tr >
                    <td>Timestamp : </td>
                    <td>{this.state.timestamp}</td>
                </tr>
                <tr >
                    <td>Block Hash :</td>
                    <td>{this.state.hash}</td>
                </tr>
                <tr >
                    <td>State Root:</td>
                    <td>{this.state.sroot}</td>
                </tr>
                <tr >
                    <td>extrinsics_root:</td>
                    <td>{this.state.eroot}</td>
                </tr>
                <tr >
                    <td>Parent :</td>
                    <td>{this.state.parent}</td>
                </tr>
                <tr >
                 <td>Transaction Count :</td>
                    <td>{this.state.tnx}</td>
                </tr>
                 
                </tbody>
              </table>

              </CCardBody>
          </CCard>}


          <CCard>
            <CCardHeader>
              Transactions
            </CCardHeader>
            <CCardBody>
            {(this.state.tnxdata.length>0)?

          
                <table className="table table-hover table-outline mb-0 table-responsive d-sm-table">
                <thead className="thead-light">
                <tr>
                    <th className="text-left">Block Id</th>
                    <th className="text-left">Transactions Type</th>
                  
                </tr>
                </thead>
                <tbody>
                {this.state.tnxdata.map(e =>
               <tr onClick ={(item) => history.push(`/tnx-details/${e.id}`)}>
             
                <td>
                  <div className="small text-muted">Last block created</div>
                  <strong>{e.id}</strong>
                </td>
                <td>
                  <div className="small text-muted">Last block created</div>
                  <strong>{e.type}</strong>
                </td>
                
                </tr>

                
                
               


                )}
                
                </tbody>
                </table>
              :<div>No Transaction Found</div>}
                  
              </CCardBody>
          </CCard>

       
                
            </div>
                
            </div>
        );
    }
}

export default withRouter(Block);
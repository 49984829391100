import React, { Component } from 'react';
import {
 
  CCard,
  CCardBody,
  CCardHeader,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { withRouter } from 'react-router-dom'    
import {APIS} from '../../config'


class Transactions extends Component {

    state = {
        myArr : [],
        transArr : [],
        intervalId : '',
  
      }
    
      constructor(props) {
        super(props)
        this.loadData = this.loadData.bind(this)
    }
    
      componentDidMount() {
        this.loadData()
        let intervalId = setInterval(this.loadData, 10000);
        this.setState({ intervalId: intervalId })

      }

      componentWillUnmount(){
        clearInterval(this.state.intervalId)
        this.setState = (state,callback)=>{
          return;
      };
      }
    
      async loadData() {
         try {
          const token = sessionStorage.getItem('login')
          let res1;
          if(token === null) {
           res1 = await fetch(APIS+'balances/transfer');
          } else {
           res1 = await fetch(APIS+'balances/transfer', {
           headers: new Headers({
             'Authorization': token }) 
           });
          }
            // const res1 = await fetch(APIS+'balances/transfer');
    
            let balanceTrans = await res1.json();
    
    
            this.setState({transArr: balanceTrans.data})
    
        } catch (e) {
            console.log(e);
        }
      }



    render() {
      const {history} = this.props;
        return (
            <div>
                 <CCard>
            <CCardHeader>
            MetaMUI Transactions
            </CCardHeader>
            <CCardBody>
              

            <table className="table table-hover table-outline mb-0 table-responsive d-sm-table">
                <thead className="thead-light">
                  <tr>
                    <th className="text-center"><CIcon name="cil-people" /></th>
                    <th>Sender</th>
                    <th className="text-center">Receiver</th>
                    {/* <th className="text-center">Amount</th> */}
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>

                {this.state.transArr.map((e,index) =>

                          <tr onClick={()=>history.push('/tnx-details/'+e.id)} key = {index}>
                          <td className="text-center">
                            <div className="c-avatar">
                             <strong>{index + 1} </strong>
                            </div>
                          </td>
                          <td>
                            <div><strong>{e.attributes.sender.attributes.address}</strong></div>
                            <div className="small text-muted">
                              <span>Block</span> | Added <strong>: {e.id}</strong>
                            </div>
                          </td>
                          <td className="text-center">
                            {/* <CIcon height={25} name="cif-us" title="us" id="us" /> */}
                            <div><strong>{e.attributes.destination.attributes.address}</strong></div>
                          </td>
                      
                          {/* <td className="text-center">
                            <CIcon height={25} name="cib-cc-mastercard" />
                          </td> */}
                          <td>
                            <div className="small text-muted">Last block created</div>
                            <strong>{e.attributes.value/1e6} MetaMUI </strong>
                          </td>
                          </tr>


                          )}

               
                </tbody>
              </table>

              </CCardBody>
          </CCard>

                
                
            </div>
        );
    }
}

export default withRouter(Transactions);
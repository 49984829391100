import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CLink,
  CToggler,
  CHeaderBrand,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";

import { withRouter } from "react-router-dom";

import InputBase from "@material-ui/core/InputBase";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";

import swal from "sweetalert2";
import { did } from "mui-metablockchain-sdk";
import {
  METAMUI_ADMINS,
  PAIRWISE_WEBRTC,
  PAIRWISE_WEBRTC_V2,
  REACT_APP_EXPLORER_DID,
} from "../config";
import imageName from "../assets/icons/m.png";
import imageName1 from "../assets/icons/m2.png";
import { useWebSocketHook } from "src/hooks/useWebsocketHook";
import { toHex } from "src/utils";
import { utils } from "mui-metablockchain-sdk";
import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";
import {
  checkIfPasskeyRegistered,
  finishPassKeyAuth,
  finishPassKeyRegister,
  startPassKeyAuth,
  startPassKeyRegister,
} from "src/services/passkeys";

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 2,
    //marginLeft: 50,
    marginTop: 10,
  },
  imgs: {
    marginTop: 10,
    marginLeft: 25,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    color: "black",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,

    color: "white",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    minWidth: "400px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    // marginLeft: 118
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(2em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "38ch",
      "&:focus": {
        width: "38ch",
      },
    },
  },
}));

const configuration = {
  iceServers: [{ urls: "stun:stun.1.google.com:19302" }],
};

const TheHeader = (props) => {
  let connection = new RTCPeerConnection(configuration);

  const classes = useStyles();
  const history = useHistory();
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [socketMessages, setSocketMessages] = useState([]);
  const [setIsLoggedIn] = useState(false);
  const [name, setName] = useState("");
  // const [setLoggingIn] = useState(false);
  const [users, setUsers] = useState([]);
  const [setConnectedTo] = useState("");
  const connectedRef = useRef();
  const webSocket = useRef(null);
  const messagesRef = useRef({});
  const [setMessages] = useState({});
  const [setTokens] = useState("");
  const [logstat, setLogstat] = useState(false);
  const [showRichWallets, setShowRichWallets] = useState(false);
  const [showPasskeyModal, setShowPasskeyModal] = useState(false);

  const sidebarShow = useSelector((state) => state.sidebarShow);
  const location = useLocation();

  const wsLifeCycle = {
    ClientLoginRequestAck: (ws) => {
      console.log(ws.message);
    },
    ClientLoginRequestConfirmation: (ws) => {
      const { message, send } = ws;
      if (message?.data?.confirmation) {
        onLoggedInSuccessV2(message.data.token, name);
        // Show Passkey Registration modal
        setShowPasskeyModal(true);
      }
    },
  };
  const { webSocketSend } = useWebSocketHook(PAIRWISE_WEBRTC_V2, wsLifeCycle);

  // console.log('sidebar status:',sidebarShows)

  const toggleSidebar = (tok) => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
    dispatch({ type: "test", count: name });
  };

  const showSidebar = () => {
    const val = "responsive";
    setLogstat(true);
    dispatch({ type: "set", sidebarShow: val });
  };

  const hideSidebar = () => {
    const val = false;
    setLogstat(false);
    dispatch({ type: "set", sidebarShow: val });
  };

  const decodeV1Token = (token) => {
    let decoded = JSON.parse(atob(token.split(".")[1]));
    return decoded;
  };

  const decodeV2Token = (token) => {
    let decoded = JSON.parse(atob(token.split(":")[1]));
    return decoded;
  };

  const showRichWalletsIfAdmin = (decodedToken) => {
    let userDid = decodedToken.data.did;
    if (METAMUI_ADMINS.includes(userDid)) {
      setShowRichWallets(true);
    }
  };

  const canShowRichWalletsv2 = (userDid, userGroups) => {
    let showRichWallets = false;
    let allowedGroups = [
      "council_members",
      "kyc_admins",
      "kyc-admins",
      "ssid-admin",
    ];

    if (METAMUI_ADMINS.includes(userDid)) {
      return true;
    }

    userGroups.forEach((group) => {
      if (allowedGroups.includes(group)) {
        showRichWallets = true;
      }
    });
    return showRichWallets;
  };

  useEffect(() => {
    let token = sessionStorage.getItem("login");
    let tokenType = sessionStorage.getItem("tokenType");

    if (token && tokenType === "v1") {
      let decodedToken = decodeV1Token(token);
      let token_expiry = decodedToken.exp;
      if (Math.round(Date.now() / 1000) > token_expiry) {
        return logout();
      }
      showRichWalletsIfAdmin(decodedToken);
      showSidebar();
    }
    if (token && tokenType === "v2") {
      let decodedToken = decodeV2Token(token);
      let token_expiry = decodedToken.expiry;
      if (Math.round(Date.now() / 1000) > token_expiry) {
        return logout();
      }
      showRichWalletsIfAdminV2(decodedToken);
      showSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const showRichWalletsIfAdminV2 = (v2DecodedToken) => {
    console.log("Decoded Token:", v2DecodedToken);
    let userGroups = v2DecodedToken.user_role.split(",");
    let userDid = utils.hexToString(v2DecodedToken.user_did);
    console.log("User DID:", userDid);
    console.log("User Groups:", userGroups);
    setShowRichWallets(canShowRichWalletsv2(userDid, userGroups));
  };

  const logout = () => {
    hideSidebar();
    setLogstat(false);
    setName("");
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("userDid");
    sessionStorage.removeItem("tokenType");
    props.history.push("/dashboard");
    window.location.reload();
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const swalActive = () => {
    swal.fire({
      position: "top-end",
      title: "Submit your DID ",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      footer: "Pls make sure your METAMUI SSID App is open on your mobile",
      showCancelButton: true,
      confirmButtonText: "Look up",
      showLoaderOnConfirm: true,
      preConfirm: (data) => {
        onSwal(data);
      },
    });
  };

  const onSwal = (did) => {
    setName(did);
    authenticateWithPasskey(did);
  };

  const authenticateWithPasskey = async (did) => {
    try {
      let hasAlreadyRegistered = await checkIfPasskeyRegistered(did);
      if (!hasAlreadyRegistered.data?.already_registered) {
        throw new Error("Passkey Not Registered");
      }
      let response = await startPassKeyAuth(did);
      // Pass the options to the authenticator and wait for a response
      let authCredentials = await startAuthentication(
        response.data?.challenge?.publicKey
      );
      let verificationResponse = await finishPassKeyAuth(authCredentials, did);
      if (verificationResponse.data?.verified) {
        return onLoggedInSuccessV2(verificationResponse.data?.token, did);
      }
      throw new Error("Passkey Authentication failed");
    } catch (error) {
      console.log("Passkey Authentication failed");
      console.error(error);
      doLogin(did);
    }
  };

  const registerWithPasskey = async (did) => {
    try {
      setShowPasskeyModal(false);
      let token = sessionStorage.getItem("login");
      let response = await startPassKeyRegister(did, token);
      // Pass the options to the authenticator and wait for a response
      let authCredentials = await startRegistration(
        response.data?.challenge?.publicKey
      );
      let verificationResponse = await finishPassKeyRegister(
        authCredentials,
        did,
        token
      );
      if (verificationResponse.data?.verified) {
        return console.log("Successfully registered with passkey");
      }
      throw new Error("Passkey Authentication failed");
    } catch (error) {
      console.log("Passkey Authentication failed");
      console.error(error);
    }
  };

  const doLogin = (did) => {
    handleLogin(did);
    handleLoginv2(did);
  };

  useEffect(() => {
    webSocket.current = new WebSocket(PAIRWISE_WEBRTC);
    console.log("Web socket initialized v1");

    webSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("1st:", data);

      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = () => {
      webSocket.current.close();
    };
    return () => webSocket.current.close();
  }, []);

  useEffect(() => {
    let data = socketMessages.pop();
    console.log("2st:", data);

    if (data) {
      switch (data.type) {
        case "connect":
          checkLoginStatus();
          break;
        case "client-login":
          onLogin(data);
          break;
        case "response-login":
          onLoggedInSuccess(data.token);
          break;
        case "updateUsers":
          updateUsersList(data);
          break;
        case "removeUser":
          // removeUser(data);
          break;
        case "offer":
          onOffer(data);
          break;
        case "answer":
          onAnswer(data);
          break;
        case "candidate":
          onCandidate(data);
          break;
        case "leave":
          console.log("perosn leaved");
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [socketMessages]);

  const checkLoginStatus = () => {
    send({
      type: "login",
      name: REACT_APP_EXPLORER_DID,
    });
  };

  const send = (data) => {
    webSocket.current.send(JSON.stringify(data));
  };

  const handleLoginv2 = async (data) => {
    // let provider = await CSK.buildConnection(netstat);
    // let convertName = await did.resolveDIDToAccount(
    //   { identifier: data },
    //   provider
    // );
    console.log(data);
    if (data?.startsWith("did")) {
      data = toHex(data);
    }
    webSocketSend({
      event: "ClientLoginRequest",
      data: {
        my_did: data,
        pair_did:
          "0x6469643a737369643a6d6574616d75695f6261636b656e640000000000000000",
        app_id: "MMUIEXPL",
        timestamp: new Date().getTime(),
      },
    });

    return;
  };

  const handleLogin = async (data) => {
    // let provider = await CSK.buildConnection(netstat);
    // let convertName = await did.resolveDIDToAccount(
    //   { identifier: data },
    //   provider
    // );
    // console.log(convertName)
    console.log("websocket v1 send login request");
    send({
      type: "client-login",
      name: data,
      source: "explorer",
    });
    return;
  };

  const updateUsersList = ({ user }) => {
    setUsers((prev) => [...prev, user]);
  };

  const onLogin = ({ success, message, users: loggedIn }) => {
    //setLoggingIn(false);
    if (success) {
      console.log("its a login success");
      setIsLoggedIn(true);
      setUsers(loggedIn);
      let localConnection = new RTCPeerConnection(configuration);
      //when the browser finds an ice candidate we send it to another peer
      localConnection.onicecandidate = ({ candidate }) => {
        let connectedTo = connectedRef.current;

        if (candidate && !!connectedTo) {
          send({
            name: connectedTo,
            type: "candidate",
            candidate,
          });
        }
      };
      localConnection.ondatachannel = (event) => {
        console.log("Data channel is created!");
        let receiveChannel = event.channel;
        receiveChannel.onopen = () => {
          console.log("Data channel is open and ready to be used.");
        };
        receiveChannel.onmessage = handleDataChannelMessageReceived;
        // updateChannel(receiveChannel);
      };
      // updateConnection(localConnection);
    } else {
      console.log("its a login fail");
    }
  };

  const onLoggedInSuccess = (token) => {
    // onLogin(data);
    setLogstat(true);
    //setTokens(data.token)
    sessionStorage.setItem("userDid", name);
    sessionStorage.setItem("login", token);
    sessionStorage.setItem("tokenType", "v1");
    const decodedToken = decodeV1Token(token);
    showRichWalletsIfAdmin(decodedToken);

    showSidebar();
  };

  const onLoggedInSuccessV2 = (token, did) => {
    setLogstat(true);
    sessionStorage.setItem("userDid", did);
    sessionStorage.setItem("login", token);
    sessionStorage.setItem("tokenType", "v2");
    if (token) {
      let decodedToken = decodeV2Token(token);
      showRichWalletsIfAdminV2(decodedToken);
    }
    showSidebar();
  };

  const handleDataChannelMessageReceived = ({ data }) => {
    const message = JSON.parse(data);
    const { name: user } = message;
    let messages = messagesRef.current;
    let userMessages = messages[user];
    if (userMessages) {
      userMessages = [...userMessages, message];
      let newMessages = Object.assign({}, messages, { [user]: userMessages });
      messagesRef.current = newMessages;
      setMessages(newMessages);
    } else {
      let newMessages = Object.assign({}, messages, { [user]: [message] });
      messagesRef.current = newMessages;
      setMessages(newMessages);
    }
  };

  const onOffer = ({ offer, name }) => {
    setConnectedTo(name);
    connectedRef.current = name;

    connection
      .setRemoteDescription(new RTCSessionDescription(offer))
      .then(() => connection.createAnswer())
      .then((answer) => connection.setLocalDescription(answer))
      .then(() =>
        send({ type: "answer", answer: connection.localDescription, name })
      )
      .catch((e) => {
        console.log({ e });
      });
  };

  //when another user answers to our offer
  const onAnswer = ({ answer }) => {
    connection.setRemoteDescription(new RTCSessionDescription(answer));
  };

  //when we got ice candidate from another user
  const onCandidate = ({ candidate }) => {
    connection.addIceCandidate(new RTCIceCandidate(candidate));
  };

  // console.log(history)
  const keyPress1 = async (e) => {
    let str = count;
    var n =
      str.startsWith("did:ssid:") ||
      str.startsWith("did:yidinji:") ||
      str.startsWith("did:yidindji:");
    let didLength = 0;
    let didPrefix = "";
    if (str.startsWith("did:ssid:")) {
      didLength = 9;
      didPrefix = "did:ssid:";
    }
    if (str.startsWith("did:yidinji:")) {
      didLength = 12;
      didPrefix = "did:yidinji:";
    }
    if (str.startsWith("did:yidindji:")) {
      didLength = 13;
      didPrefix = "did:yidindji:";
    }

    if (n) {
      var r = str.substring(didLength);
      var uri = r;

      var tak = encodeURIComponent(uri);
      let identifier = did.sanitiseDid(str);
      history.push("/account/" + identifier, {
        did: didPrefix + tak,
        orginal: str,
      });
    } else {
      n = str.length;
      if (n === 64) {
        history.push("tnx-details/0x" + str);
      } else {
        history.push("/block/" + str);
      }
    }
  };

  const keyPress = async (e) => {
    if (e.keyCode === 13) {
      let str = e.target.value;
      var n =
        str.startsWith("did:ssid:") ||
        str.startsWith("did:yidinji:") ||
        str.startsWith("did:yidindji:");
      let didLength = 0;
      let didPrefix = "";
      if (str.startsWith("did:ssid:")) {
        didLength = 9;
        didPrefix = "did:ssid:";
      }
      if (str.startsWith("did:yidinji:")) {
        didLength = 12;
        didPrefix = "did:yidinji:";
      }
      if (str.startsWith("did:yidindji:")) {
        didLength = 13;
        didPrefix = "did:yidindji:";
      }
      if (n) {
        var r = str.substring(didLength);
        var uri = r;
        var tak = encodeURIComponent(uri);
        let identifier = did.sanitiseDid(str);
        history.push("/account/" + identifier, {
          did: didPrefix + tak,
          orginal: str,
        });
      } else {
        n = str.length;
        if (n === 64) {
          history.push("tnx-details/0x" + str);
        } else {
          history.push("/block/" + str);
        }
      }
    }
  };
  const logom = {
    width: "40px",
    height: "40px",
  };

  return (
    <div>
      <CModal
        show={showPasskeyModal}
        onClose={() => setShowPasskeyModal(false)}
        aria-labelledby="PasskeyPromptModal"
        style={{ minWidth: "670px" }}
      >
        <CModalHeader>
          <CModalTitle
            id="PasskeyPromptModalTitle"
            className={"passkey__modal__title"}
          >
            Create a passkey
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p className="passkey__title">
            Do you want to register passkey for easy and quick login?
          </p>

          <p className="passkey__subtitle">
            Passkeys are a password replacement that validates your identity
            using touch, facial recognition, a device password, or a PIN.
            Passkeys can be used for sign-in as a simple and secure alternative
            to your password and two-factor credentials.{" "}
          </p>
        </CModalBody>
        <CModalFooter className={"passkey__modal__footer"}>
          <CButton
            className={"passkey__modal__btn--secondary"}
            onClick={() => setShowPasskeyModal(false)}
          >
            No
          </CButton>
          <CButton
            className={"passkey__modal__btn--primary"}
            onClick={() => registerWithPasskey(name)}
          >
            Yes
          </CButton>
        </CModalFooter>
      </CModal>
      <div />

      <CHeader withSubheader>
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />
        {logstat && (
          <CToggler
            inHeader
            className="ml-3 d-md-down-none"
            onClick={toggleSidebar}
          />
        )}
        <CHeaderBrand className="mx-auto d-lg-none" to="/">
          {/* <CIcon name="logo" height="48" alt="Logo"/> */}
        </CHeaderBrand>

        <div className={classes.imgs}>
          <img style={logom} src={imageName} alt="first" />{" "}
          <img src={imageName1} alt="first" />
        </div>

        <CHeaderNav className="d-md-down-none mr-auto" />

        <div className={classes.root}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="DID, Block No…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => setCount(e.target.value)}
              onKeyDown={keyPress}
            />
          </div>
        </div>
        {/* <div className={classes.root} >
          <button type="button" class="btn btn-vimeo"><span class="cib-vimeo btn-icon mr-2"></span> Search</button>

          </div> */}

        <CHeaderNav className="px-2">
          <button type="button" onClick={keyPress1} className="btn btn-vimeo">
            Search
          </button>

          {/* <TheHeaderDropdownNotif/>
        <TheHeaderDropdownTasks/>
        <TheHeaderDropdownMssg/>
        <TheHeaderDropdown/> */}
        </CHeaderNav>

        <CSubheader className="px-3 justify-content-between">
          {/* <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        /> */}
          <CHeaderNav>
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/dashboard">
                <strong>Dashboard</strong>
              </CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/blocks">
                <strong>Blocks</strong>
              </CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/transactions">
                <strong>Transactions</strong>
              </CHeaderNavLink>
            </CHeaderNavItem>
            {showRichWallets && (
              <CHeaderNavItem className="px-3">
                <CHeaderNavLink to="/rich-wallets">
                  <strong>Top Holders</strong>
                  <CIcon
                    style={{ margin: "10px", color: "red" }}
                    name="cil-speedometer"
                  />
                </CHeaderNavLink>

                {/* <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/> */}
                {/* <CCreateElement items ={nav1}  components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}/> */}
              </CHeaderNavItem>
            )}
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/events">
                <strong>Events</strong>
              </CHeaderNavLink>
            </CHeaderNavItem>
          </CHeaderNav>
          <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink className="c-subheader-nav-link" href="#">
              {/* <CIcon name="cil-speech" alt="Settings" /> */}
            </CLink>
            <CLink
              className="c-subheader-nav-link"
              aria-current="page"
              to="/dashboard"
            >
              {/* <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard */}
            </CLink>
            <CLink className="c-subheader-nav-link" href="#">
              {/* <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings */}
              {!logstat ? (
                <VpnKeyIcon onClick={() => swalActive()} />
              ) : (
                <ExitToAppIcon onClick={() => logout()} />
              )}
            </CLink>
          </div>
        </CSubheader>
      </CHeader>
    </div>
  );
};

export default withRouter(TheHeader);

import React, { useEffect, useState } from 'react';
import { bnToBn } from '@polkadot/util';
import { CWidgetDropdown } from '@coreui/react'
import ChartLineSimple from '../charts/ChartLineSimple'
const TICK_TIMEOUT = 100;
const tickers = new Map();
let lastNow = Date.now();
let lastId = 0;
function tick() {
    lastNow = Date.now();
    for (const ticker of tickers.values()) {
        ticker(lastNow);
    }
    setTimeout(tick, TICK_TIMEOUT);
}
function getDisplayValue(now = 0, value = 0) {

    const tsValue = (value && value.getTime
        ? value.getTime()
        : bnToBn(value).toNumber()) || 0;
    let display = '0.0 s';
    if (now && tsValue) {
        const elapsed = Math.max(Math.abs(now - tsValue), 0) / 1000;

        display = `${elapsed.toFixed(1)} s`;
    
    }
    return display
}
tick();
function Elapsed({ value }) {
    const [now, setNow] = useState(lastNow);
    useEffect(() => {
        const id = lastId++;
        tickers.set(id, setNow);
        return () => {
            tickers.delete(id);
        };
    }, []);
    return (
      <React.Fragment>
        <CWidgetDropdown
          color="gradient-info"
          header = {getDisplayValue(now,value)}
          text="Block Time"
          footerSlot={
            <ChartLineSimple
              pointed
              className="mt-3 mx-3"
              style={{height: '70px'}}
              dataPoints={[1, 1, 1, 1, 1]}
              pointHoverBackgroundColor="info"
              options={{ elements: { line: { tension: 0.1 }}}}
              label="Transaction"
              labels="months"
            />
          }
        >
          
        </CWidgetDropdown>
      </React.Fragment>
    );
}
export default Elapsed;
import React, { Component } from 'react';
import {
 
  CCard,
  CCardBody,
  CCardHeader,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { withRouter } from 'react-router-dom'    
import {APIS} from '../../config'


class Rich extends Component {

   state = {
       richArr : []
   }

   async componentDidMount(){
    try {

      const token = sessionStorage.getItem('login')
      let res;
      if(token) {
        res = await fetch(APIS+'balances/top_holders', {
        headers: new Headers({
          'Authorization': token }) 
        });
      }
           
        // const res = await fetch(APIS+'balances/top_holders');

        let richTrans = await res.json();

        console.log(richTrans)
        this.setState({richArr: richTrans.data})

    } catch (e) {
        console.log(e);
    }
   }

   
    render() {

        return (
            <div>
                 <CCard>
            <CCardHeader>
              <strong>Top Holders</strong>
            </CCardHeader>
            <CCardBody>
              
            <div className="table-wrapper-scroll-y my-custom-scrollbar"  style={{maxHeight: '600px'}}>

            <table className="table table-hover table-outline mb-0 table-responsive d-sm-table " >
                <thead className="thead-light">
                  <tr>
                    <th className="text-center"><CIcon name="cil-people" /></th>
                    <th>Account Id</th>
                    <th>Balance</th>
                    {/* <th className="text-center">Reserved Balance</th>
                    <th className="text-center">Free Balance</th> */}

                    {/* <th className="text-center">Amount</th> */}
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>

                {this.state.richArr.map((e, index) =>

                          <tr>
                          <td className="text-center">
                            <div className="c-avatar">
                            <strong>  {index + 1} </strong>
                            </div>
                          </td>

                          <td>
                            <div className="small text-muted"></div>
                             <strong>{e.did} </strong>
                          </td>
                          

                          <td >
                                <strong> {e.balance_total} MetaMUI </strong>
                                <div className="small text-muted">
                              <span>Reserved<strong> : {e.balance_reserved} </strong></span> | Free<strong> : {e.balance_free} </strong>
                            </div>
                          </td>

                          <td >
                                <strong> {e.percentage} % </strong>
                          </td>
                            {/* <div className="small text-muted">
                              <span>Block</span> | Added: {e.id}
                            </div> */}
                          
                       
                      
                          {/* <td className="text-center">
                            <CIcon height={25} name="cib-cc-mastercard" />
                          </td> */}
                    
                          </tr>


                          )}

               
                </tbody>
              </table>
</div>
              </CCardBody>
          </CCard>

                
                
            </div>
        );
    }
}

export default withRouter(Rich);
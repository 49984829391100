import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {

  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
} from '@coreui/react'
import {APIS} from '../../config'
import { useSelector} from 'react-redux'
import { did } from 'mui-metablockchain-sdk';

// function Spinner() {
//   return (
//     <div className = "row justify-content-center" style = {{height : "300px", width : "100%"}}>
//       <div className = "col-1 align-self-center">
//         <div className="spinner-border" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>>
//     </div>
//   );
// }


const Users = (props) => {
  const history = useHistory()
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
  const [activePage, setActivePage] = useState(1)
  const [group, setGroup] = useState(0)
  const [tx, setTx] = useState([])
  const didName = useSelector(state => state.count)
  const [searchPg, setSearchPg] = useState(null)

   useEffect(() => {
   
    getData()
    console.log('AP'+ activePage + 'GP' + group);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.ssid, props.selectedToken, activePage])
 
 const getData = async()=>{
    //  var r = props.ssid.substring(9);

     if(props.ssid === undefined){
      const token = sessionStorage.getItem('login')
      let res1;
       let sanitisedDid = did.sanitiseDid(didName || sessionStorage.getItem("userDid"));
       console.log('sanitisedDid', sanitisedDid)
      if(token === null) {
       res1 = await fetch(APIS+`balances/transfer_history/`+sanitisedDid+`?page[size]=10&page[number]=${activePage}`);
      } else {
       res1 = await fetch(APIS+`balances/transfer_history/`+sanitisedDid+`?page[size]=10&page[number]=${activePage}`, {
       headers: new Headers({
         'Authorization': token }) 
       });
      }
      // let res1 = await fetch(APIS+'balances/transfer_history/'+didName,{
      // headers: new Headers({
      //   'Authorization': sessionStorage.getItem('login') }) 
      // });
      res1 = await res1.json();
      console.log('api call', res1)
      let myArr = [];
      res1.data.map(e=>{
        myArr.push({'id':e.id,'from':e.attributes.sender.attributes.address,'to':e.attributes.destination.attributes.address,'amt':e.attributes.value/1e6})
        if(res1.data.length === myArr.length){
          setTx(myArr)

        }
        return res1;

      })
      return res1;

     } else if (props.ssid && props.selectedToken.name !== 'MetaMUI') {
      // let res1 = await fetch(`${APIS}balances/token_history/?filter[did]=${props.ssid}&filter[currency]=${props.selectedToken.currencyCode}`)
      //                   .catch(err => console.log('Selected token History is not available'));
      const token = sessionStorage.getItem('login')
      let res1;
      if(token === null) {
        res1 = await fetch(`${APIS}balances/token_history/?filter[did]=${props.ssid}&filter[currency]=${props.selectedToken.currencyCode}`)
        .catch(err => console.log('Selected token History is not available'));
      } else {
        res1 = await fetch(`${APIS}balances/token_history/?filter[did]=${props.ssid}&filter[currency]=${props.selectedToken.currencyCode}`, {
                headers: new Headers({
                  'Authorization': token }) 
                })
                .catch(err => console.log('Selected token History is not available'));
      }
      res1 = await res1.json();
      console.log('Selected token history is........', res1);
      let myArr = [];
      if(res1.data.length === 0){
        setTx(myArr)
      }
      res1.data.map(e=>{
        myArr.push({'id':e.id,'from':e.attributes.sender.attributes.address,'to':e.attributes.destination.attributes.address,'amt':e.attributes.value})
        if(res1.data.length === myArr.length){
          setTx(myArr)

        }
        return res1;

      })
      return res1;
     }
     else if ((props.ssid && props.selectedToken.name === 'MetaMUI')){
      const token = sessionStorage.getItem('login')
      let res1;
      let sanitisedDid = did.sanitiseDid(props.ssid);
      if(token === null) {
       res1 = await fetch(APIS+'balances/transfer_history/'+sanitisedDid+`?page[size]=10&page[number]=${activePage}`);
      } else {
       res1 = await fetch(APIS+'balances/transfer_history/'+sanitisedDid+`?page[size]=10&page[number]=${activePage}`, {
       headers: new Headers({
         'Authorization': token }) 
       });
      }
      // let res1 = await fetch(APIS+'balances/transfer_history/'+props.ssid);
      res1 = await res1.json();
      let myArr = [];
      if(res1.data.length === 0){
        setTx(myArr)
      }
      res1.data.map(e=>{
        myArr.push({'id':e.id,'from':e.attributes.sender.attributes.address,'to':e.attributes.destination.attributes.address,'amt':e.attributes.value/1e6})
        if(res1.data.length === myArr.length){
          setTx(myArr)

        }
        return res1;

      })
      console.log(res1)
      return res1;

     }
   
    
 }




  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
        
          <CCardBody>
          <CDataTable
            items={tx}
            fields={[
              { key: 'id', _classes: 'font-weight-bold' },
              { key: 'from', _classes: 'font-weight-bold' },
              { key: 'to', _classes: 'font-weight-bold' },
              { key: 'amt', _classes: 'font-weight-bold' },
            ]}
            hover
            striped
            // itemsPerPage={5}
            activePage={1}
            clickableRows
            onRowClick={(item) => {
                history.push(`/tnx-details/${item.id}/${props.selectedToken ? props.selectedToken.name : 'MetaMUI'}`); 
              }
            }
            noItemsView={{noItems: 'No Transactions Found' }}

          />
          { (props.ssid === undefined || (props.ssid && props.selectedToken.name === 'MetaMUI')) && 
            <div className = "container">
            <div className = "row justify-content-end">
              <div className = "col-5">
                <div className="btn-toolbar" role="toolbar" aria-label="Pagination">
                  <div className="btn-group" role="group" aria-label="Pagination">
                    <button 
                      type="button" 
                      className="btn btn-outline-dark" 
                      onClick = {() => {setActivePage(activePage - 1); if (activePage % 5 === 1) {
                        setGroup(group -1)
                      }}} 
                      disabled = {activePage === 1}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                    <button 
                      type="button" 
                      className={`btn btn-outline-dark ${activePage === (5 * group + 1) ? 'active' : ''}`} 
                      onClick = {() => {setActivePage(5*group+1); setTx([]);}} 
                      disabled = {(tx && tx.length < 10) && (5 * group + 1) > activePage ? true : false}
                    >
                      {5 * group + 1}
                    </button>
                    <button 
                      type="button" 
                      className={`btn btn-outline-dark ${activePage === (5 * group + 2) ? 'active' : ''}`} 
                      onClick = {() => {setActivePage(5*group+2); setTx([]);}} 
                      disabled = {(tx && tx.length < 10) && (5 * group + 2) > activePage ? true : false}
                    >
                      {5 * group + 2}
                    </button>
                    <button 
                      type="button" 
                      className={`btn btn-outline-dark ${activePage === (5 * group + 3) ? 'active' : ''}`} 
                      onClick = {() => {setActivePage(5*group+3); setTx([]);}} 
                      disabled = {(tx && tx.length < 10) && (5 * group + 3) > activePage ? true : false}
                    >
                     {5 * group + 3}
                    </button>
                    <button 
                      type="button" 
                      className={`btn btn-outline-dark ${activePage === (5 * group + 4) ? 'active' : ''}`} 
                      onClick = {() => {setActivePage(5*group+4); setTx([]);}} 
                      disabled = {(tx && tx.length < 10) && (5 * group + 4) > activePage ? true : false}
                    >
                      {5 * group + 4}
                    </button>
                    <button 
                      type="button" 
                      className={`btn btn-outline-dark ${activePage === (5 * group + 5) ? 'active' : ''}`} 
                      onClick = {() => {setActivePage(5*group+5); setTx([]);}} 
                      disabled = {(tx && tx.length < 10) && (5 * group + 5) > activePage ? true : false}
                    >
                     {5 * group + 5}
                    </button>
                    <button 
                      type="button" 
                      className="btn btn-outline-dark" 
                      onClick = {() => {setActivePage(activePage + 1); if(activePage % 5 === 0) {
                        setGroup(group + 1)
                      }}} 
                      disabled = { tx && tx.length < 10}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className = "col-3">
                <div className="btn-group" role="group" aria-label="Pagination">
                  <input type="number" min="1" className = "mx-2 form-control" placeholder = "Enter page no" onChange = {(e) => {
                    setSearchPg(e.target.value);
                  }}/>
                  <button 
                    type="button" 
                    className="btn btn-outline-success mx-2" 
                    onClick = {() => { setActivePage(searchPg); setGroup(searchPg % 5 === 0 ? searchPg / 5 - 1 : (searchPg - searchPg % 5) / 5); setTx([]);}}
                    disabled = { searchPg < 1 ? true : false} 
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
          </div>
          }
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default Users
